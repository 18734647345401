import { Container } from "@material-ui/core";
import React, { FC, PropsWithChildren } from "react";
import { Button } from "src/components/atoms/button/button";
import { LoadingScreen } from "src/components/molecules/loadingScreen/loadingScreen";
import { useAuthContext } from "src/contexts/authContext";
import { Page } from "src/layouts/page";

/**
 * Dedicated component to catch and display: Axios Errors & Unhandle promise rejection
 */
export const AuthGuard: FC<PropsWithChildren<{}>> = ({ children }) => {
    const { shouldLogin, loginRedirect, loading } = useAuthContext();
    if(loading){
        return <LoadingScreen show />
    }
    if (shouldLogin) {
        return <Page pageClass="auth-guard">
            <Container className="auth-guard__container">
                <img src="./logo-patek.svg" alt="Patek Philippe" className="auth-guard__logo" />
                <p className="auth-guard__desc">
                    Se connecter avec le compte Patek Philippe
                </p>
                <Button onClick={() => loginRedirect()} color="secondary">SSO</Button>

            </Container>
        </Page>

    
    }
    return <>{children}</>
};

