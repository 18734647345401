import { AxiosError } from "axios";
import React, { FC, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "src/components/atoms/container/container";
import { Icon } from "src/components/atoms/icon/icon";
import { ErrorScreen } from "src/components/molecules/errorScreen/errorScreen";
import { AppContext } from "src/contexts/appContext";
import { CustomError } from "src/helpers/CustomError";
import {
  CommonErrorMessage,
  CommonNetworkCode,
  ErrorCodes,
  getErrorByStatus,
} from "src/helpers/enum";
import { Page } from "src/layouts/page";

export type ErrorPageMessage = {
  title: string;
  message: string;
};
const defaultErrorCode = ErrorCodes.UNKNOWN_ERROR_ROUTER;
const defaultErrorMessages = {
  title: "Erreur",
  message: "Veuillez recharger la page ou revenir plus tard",
};

/**
 * Dedicated component to catch and display: Axios Errors & Unhandle promise rejection
 */
export const ErrorPage: FC<{children: ReactNode}> = ({children}) => {
  const { api } = useContext(AppContext);
  const history = useHistory();
  const [error, setError] = useState<ErrorPageMessage | null>(null);
  const [errorCode, setErrorCode] = useState<ErrorCodes>(defaultErrorCode);
  const [info, setInfo] = useState<string | null>(null);

  // HANDLE AXIOS ERROR
  useEffect(() => {
    api.onError((e: AxiosError) => {
      if (e.code === CommonNetworkCode.CONNECTION_ABORTED) {
        setError({
          ...defaultErrorMessages,
          title: "Erreur Réseau",
        });
        setErrorCode(ErrorCodes.BROWSER_CONNECTION_ABORTED);
      } else if (e.response?.status) {
        setError(defaultErrorMessages);
        setErrorCode(getErrorByStatus(e.response.status));
        setInfo(`network status: ${e.response.status}`);
      } else if (e.code) {
        setError(defaultErrorMessages);
        setErrorCode(ErrorCodes.BROWSER_UNKNOWN_ERROR);
        setInfo(`browsercode: ${e.code}`);
      } else {
        setError((val) => {
          return {
            title: "Erreur",
            message: "Veuillez recharger la page ou revenir plus tard",
          };
        });
        setInfo(null);
        setErrorCode(defaultErrorCode);
      }
    });
  }, []); // eslint-disable-line
  const promiseRejectionHandler = useCallback((event: PromiseRejectionEvent) => {
    if (event.reason instanceof CustomError) {
      setError({
        ...defaultErrorMessages,
        message: event.reason.message || defaultErrorMessages.message,
      });
      if (event.reason.info) {
        setInfo(event.reason.info);
      }

      setErrorCode(event.reason.code);
    } else if (event.reason) {
      console.log(event.reason);
      if (event.reason.message === CommonErrorMessage.NETWORK_ERROR) {
        setError({
          ...defaultErrorMessages,
          title: "Erreur Réseau",
        });
        setErrorCode(ErrorCodes.PROMISE_REJECTION_NETWORK_ERROR);
      } else {
        setError(defaultErrorMessages);
        setErrorCode(ErrorCodes.PROMISE_REJECTION_UNKNOWN);
      }
      if (event.reason.message) {
        setInfo(event.reason.message);
      }
      if (event.reason?.request?.responseURL) {
        setInfo((info) => `${info} \n request url: ${event.reason.request.responseURL} `);
      }
    } else {
      setError(defaultErrorMessages);
      setErrorCode(ErrorCodes.PROMISE_REJECTION_UNKNOWN);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("unhandledrejection", promiseRejectionHandler);
    return () => window.removeEventListener("unhandledrejection", promiseRejectionHandler);
  });
  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error])
  const handleBack = () => {
    setError(null);
    setErrorCode(defaultErrorCode);
    history.goBack();
  };
  return (
    <>
      <Page pageClass={!error ? "error-page" : "error-page error-page--active"}>
        {error && (
          <Container>
            {history && (
              <button className="error-page__back" onClick={handleBack}>
                <Icon name="arrow_left" color="brownishGrey"></Icon>
              </button>
            )}
            <ErrorScreen info={info} code={errorCode}>
              <h1>{error?.title}</h1>
              <div>{error?.message}</div>
            </ErrorScreen>
          </Container>
        )}
      </Page>
    {children}
    </>
  );
};
