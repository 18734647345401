
export const routes = {
    home: "/",
    // NEWS PAGE
    job: "/offers",
    jobDetail: "/offers/:id",
    events: "/events",
    eventsDetail: "/events/:id",
    newsInformation: "/news-rh",
    newsInformationDetail: "/news-rh/:id",
    newsCompany: "/vie-entreprise",
    newsCompanyDetail: "/vie-entreprise/:id",
    newsOrganisation: "/news-entreprise",
    newsOrganisationDetail: "/news-entreprise/:id",
    newsRestaurant: "/actu-resto",
    newsRestaurantDetail: "/actu-resto/:id",
    newsSport: "/news-sport",
    newsSportDetail: "/news-sport/:id",
    newsCommittee: "/news-commission",
    newsCommitteeDetail: "/news-commission/:id",
    delegationSyndicale: "/delegation-syndicale",
    delegationSyndicaleDetail: "/delegation-syndicale/:id",
    newsDontMiss: "/do-not-miss",
    newsDontMissDetail: "/do-not-miss/:id",
    // Announce
    announces: "/plus/petites-annonces",
    announcesMine: "/plus/petites-annonces/mine",
    announcesDetail: "/announce/detail/:id",
    announcesCreate: "/announce/create-update",
    announcesUpdate: "/announce/create-update/:id",
  
    // STATIC PAGES
    serviceRh: "/service-rh",
    serviceRhConfirmation: "/service-rh/confirmation",
  
    health: "/sante",
    healthAdvice: "/sante/advice",
    healthAbsence: "/sante/absence",
    healthAccident: "/sante/accident",
    trustPerson: "/sante/trust-person",
    healthAccidentStep: "/sante/accident/:step",
    restaurantDefault: "/plus/restaurants",
    restaurantOne: "/plus/restaurants/plo",
    restaurantTwo: "/plus/restaurants/pp6",
  
    hrSinglePromotion: "/annonces-rh/single-promotion",
    hrSingleDeceased: "/annonces-rh/single-promotion",
    iFrame: "/iFrame",
    upgradeAdvice: "/upgradeAdvice",
    upgradeAdviceConfirmation: "/upgradeAdviceConfirmation",
    oAuthCallback: "/callback",
    // Iframe page
    collection: "/collection",
    documentsImportant: "/documents",
    documentFolder: "/documents/dossier/:id",
  };
  export type RoutesKeys = keyof typeof routes;
  export type RoutesValues = typeof routes[RoutesKeys];
  