import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { routes } from "src/App.routes";
import { ListHomeItem } from "src/contexts/appContext";
import { bemModifier } from "../../../../helpers/bemModifier";
import { ButtonIcon } from "../../../molecules/buttonIcon/buttonIcon";

export type ListHomeProps = {
  items: ListHomeItem[];
};
export const ListHome: FunctionComponent<ListHomeProps> = ({ items, ...modifiers }) => {
  const Buttons = items.map((button, index) => {
    const link =
      button.type === "iframe"
        ? routes.iFrame +
          "?url=" +
          encodeURIComponent(button.link) +
          "&title=" +
          encodeURIComponent(button.title)
        : button.link;

    return (
      <div className="listHome__item" key={index}>
        <ButtonIcon
          link={link}
          icon={button.icon}
          notif={button.notif}
          isExternal={button.type === "external"}
        >
          {button.title}
        </ButtonIcon>
      </div>
    );
  });
  return <div className={bemModifier(modifiers, "listHome")}>{Buttons}</div>;
};
