import React, { useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "react-tiger-transition/styles/main.min.css";

import { Navigation, glideIn, glideOut } from "react-tiger-transition";

import { Home } from "./page/home/home";

import { DocumentFolder } from "./page/documents/documentFolder/documentFolderDetail";
import { DocumentsImportant } from "./page/documents/documentRoot/documentsImportant";
import { HealthAbsence } from "./page/health/healthAbsence/healthAbsence";
import { HealthAccident } from "./page/health/healthAccident/healthAccident";
import { HealthAdvice } from "./page/health/healthAdvice/healthAdvice";
import { HealthPage } from "./page/health/healthPage/healthPage";
import { TrustPerson } from "./page/health/trustPerson/trustPerson";

import { routes } from "src/App.routes";
import { HomeBtn } from "./components/molecules/homeBtn/homeBtn";
import { PwaUpdater } from "./components/organisms/pwaUpdater/pwaUpdater";
import { RouteTransition as RT } from "./components/organisms/routeTransition/routeTransition";
import { AppContext } from "./contexts/appContext";
import { getListingRoutes } from "./helpers/routeHelpers";
import { InitLoader } from "./page/_utils/initLoader/initLoader";
import { SmallAnnounceCreateUpdate } from "./page/announce/smallAnnounceCreateUpdate/smallAnnounceCreateUpdate";
import { SmallAnnounceDetail } from "./page/announce/smallAnnounceDetail/smallAnnounceDetail";
import { SmallAnnounces } from "./page/announce/smallAnnounces/smallAnnounces";
import { EventDetail } from "./page/events/eventDetail";
import { IFrame } from "./page/iFrame";
import { NewsDetailGenericPage } from "./page/news/NewsDetailGenericPage";
import { NewsGenericPage } from "./page/news/NewsGenericPage";
import { PlusEvents } from "./page/plusEvents";
import { RestaurantsMenu } from "./page/restaurantsMenu/restaurantsMenu";
import { RhService } from "./page/rhService/rhService";
import { RhServiceConfirmation } from "./page/rhService/rhServiceConfirmation";
import { UpgradeAdvice } from "./page/upgradeAdvice/upgradeAdvice";
import { UpgradeAdviceConfirmation } from "./page/upgradeAdvice/upgradeAdviceConfirmation";

export * from './App.routes';
export { getRoute, isRoute } from "./helpers/routeHelpers";
// inject glide styles
glideIn({
  name: "glide-left",
});
glideOut({
  name: "glide-right",
  direction: "right",
});

export const AppRouter = () => {
  const { isOnline, token, strings, api, getHomeItemTitle } = useContext(AppContext);

  const listingRoutes = getListingRoutes(api);
  // Check if required data is loaded
  if (!token || !strings)
    return (
      <Router>
        <InitLoader />
        <PwaUpdater key="pwaupdater" />
      </Router>
    );
  return (
    <>
      <Router>
        <PwaUpdater key="pwaupdater" />
        {!isOnline && <div className="offline">Vous êtes hors ligne</div>}
        <Navigation globalTransitionProps={{ classNames: "glide-right" }}>
          <HomeBtn />
          <RT path={routes.home} exact>
            <Home />
          </RT>
          {listingRoutes.map((item, index) => {
            return (
              <RT path={item.route} key={"list-" + index} exact>
                <NewsGenericPage
                  title={getHomeItemTitle(item.id)}
                  back={routes.home}
                  type={item.type}
                  get={item.getAll}
                />
              </RT>
            );
          })}
          {listingRoutes.map((item, index) => {
            return item.routeDetail ? (
              <RT path={item.routeDetail} key={"detail-" + index} exact>
                <NewsDetailGenericPage
                  type={item.type}
                  back={item.route}
                  get={item.getById}
                  title={getHomeItemTitle(item.id)}
                />
              </RT>
            ) : (
              <></>
            );
          })}

          <RT path={routes.events} exact>
            <PlusEvents />
          </RT>
          <RT path={routes.eventsDetail} exact>
            <EventDetail />
          </RT>
          <RT path={routes.serviceRh} exact>
            <RhService />
          </RT>
          <RT path={routes.serviceRhConfirmation} exact>
            <RhServiceConfirmation />
          </RT>

          <RT path={routes.health} exact>
            <HealthPage />
          </RT>
          <RT path={routes.healthAdvice} exact>
            <HealthAdvice />
          </RT>
          <RT path={routes.healthAbsence} exact>
            <HealthAbsence />
          </RT>
          <RT path={routes.healthAccident} exact>
            <HealthAccident />
          </RT>
          <RT path={routes.trustPerson} exact>
            <TrustPerson />
          </RT>
          <RT path={routes.healthAccidentStep}>
            <HealthAccident />
          </RT>
          <RT path={routes.restaurantDefault}>
            <RestaurantsMenu />
          </RT>
          <RT path={routes.announcesDetail} exact>
            <SmallAnnounceDetail />
          </RT>
          <RT path={routes.announcesCreate} exact>
            <SmallAnnounceCreateUpdate />
          </RT>
          <RT path={routes.announcesUpdate} exact>
            <SmallAnnounceCreateUpdate />
          </RT>
          <RT path={routes.announces}>
            <SmallAnnounces />
          </RT>
          <RT path={routes.documentsImportant} exact>
            <DocumentsImportant />
          </RT>
          <RT path={routes.documentFolder}>
            <DocumentFolder />
          </RT>
          <RT path={routes.collection}>
            <IFrame
              url="https://www.patek.com/fr/collection/tous-les-modeles​"
              title={getHomeItemTitle("collection")}
            />
          </RT>
          <RT path={routes.iFrame}>
            <IFrame />
          </RT>

          <RT path={routes.upgradeAdvice} exact>
            <UpgradeAdvice />
          </RT>
          <RT path={routes.upgradeAdviceConfirmation} exact>
            <UpgradeAdviceConfirmation />
          </RT>
        </Navigation>
      </Router>
    </>
  );
};
export default AppRouter;
