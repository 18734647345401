import React from "react";
import { AuthContextProvider } from "src/contexts/authContext";
import { AuthGuard } from "src/page/_utils/authGuard/AuthGuard";
import AppRouter from "./AppRouter";
import "./assets/scss/App.scss";
import { ToastWrapper } from "./components/atoms/toast/toastWrapper";
import { ErrorBoundary } from "./components/molecules/errorBoundary/errorBoundary";
import { AppContextProvider } from "./contexts/appContext";
import { ErrorPage } from "./page/_utils/errorPage/errorPage";

function App() {
  return (
    <ErrorBoundary name="app">
      <AuthContextProvider>
        <ErrorPage>
          <AuthGuard>
            <AppContextProvider>
              <ToastWrapper />
              <AppRouter />
            </AppContextProvider>
          </AuthGuard>
        </ErrorPage>
      </AuthContextProvider>
    </ErrorBoundary>
  );
}

export default App;
