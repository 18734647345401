import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "src/components/atoms/button/button";
import { AppContext } from "src/contexts/appContext";
import { version } from "../../../../package.json";
import * as serviceWorkerRegistration from "../../../serviceWorkerRegistration";
import { LoadingScreen } from "../../molecules/loadingScreen/loadingScreen";
export type PwaUpdaterProps = {};

export const PwaUpdater: FunctionComponent<PwaUpdaterProps> = () => {
  const [showReload, setShowReload] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  const { api } = useContext(AppContext);
  const location = useLocation();
  const reloadPage = () => {
    setShowLoader(true);
    waitingWorker?.postMessage("SKIP_WAITING");
    setTimeout(() => {
      // @ts-ignore
      window.location.reload(true);
    }, 1000);
  };
  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    const fetchManifest = async () => {
      const manifest = await api.static.getManifest();
      if (manifest.version && manifest.version !== version) {
        setShowReload(true);
      }
    };
    fetchManifest();
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []); // eslint-disable-line
  useEffect(() => {
    // check for sw updates on page change
    navigator.serviceWorker.getRegistrations().then((regs) => regs.forEach((reg) => reg.update()));
  }, [location]); // eslint-disable-line

  if (!showReload) return <></>;
  return (
    <>
      <div className="pwa-updater">
        <LoadingScreen show={showLoader} backdrop={true} />
        <div className="pwa-updater__content">
          <div className="pwa-updater__message">Une mise à jour est disponible !</div>
          <div className="pwa-updater__action">
            <Button onClick={reloadPage} outline color="white">
              Mettre à jour
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
