import * as React from "react";
import { routes } from "src/App.routes";
import { Container } from "src/components/atoms/container/container";
import { Text } from "src/components/atoms/text/text";
import { ListDocument } from "src/components/organisms/lists/listDocument/listDocument";
import { AppContext } from "src/contexts/appContext";
import { File } from "src/types/ApiItems";
import { DocumentBase } from "../documentBase/documentBase";

export const DocumentsImportant = () => {
  const [files, setFiles] = React.useState<File | any>();
  const [lastUpdated, setLastUpdated] = React.useState<File | any>();
  const { api } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    // Get All items and make tree root
    api.documentReperes.getRoot().then((result) => {
      setFiles(result);
      // Get last updated items sorted
      api.documentReperes.getLastUpdated().then((result) => {
        setLastUpdated(result);
        setLoading(false);
      });
    });
  }, []); // eslint-disable-line

  return (
    <DocumentBase loading={loading} back={routes.home}>
      <Container small>
        <section style={{ margin: ".85rem 0 1.5rem" }}>
          <Text type="h2">Liste des documents</Text>
        </section>
        <ListDocument loading={loading} files={files} />

        <section style={{ margin: "1.5rem 0" }}>
          <Text type="h2">Derniers documents publiés</Text>
        </section>
        <ListDocument loading={loading} files={lastUpdated} />
      </Container>
    </DocumentBase>
  );
};
