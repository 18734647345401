
import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CustomError } from 'src/helpers/CustomError';
import { ErrorCodes } from 'src/helpers/enum';

const tenant_ID = process.env.REACT_APP_AZURE_TENANT_ID!;
const clientId = process.env.REACT_APP_AZURE_CLIENT_ID!;
const authUrl = `https://login.microsoftonline.com/${tenant_ID}/oauth2/v2.0/authorize`;
const redirectUri = process.env.REACT_APP_AUTH_BASE+'/redirect';
const scope = encodeURIComponent(clientId + '/.default offline_access email profile openid');
const authorizationUrl = `${authUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`;

type UserData = {
  email: string;
  firstname: string;
  lastname: string;
}
type AuthContextValue = {
  accessToken?: string;
  shouldLogin: boolean,
  loginRedirect: () => void,
  logoutRedirect: () => void,
  loading: boolean
  user?: UserData
}
export const AuthContext = createContext<AuthContextValue>({
  accessToken: '',
  shouldLogin: false,
  loginRedirect: () => {},
  logoutRedirect: () => {},
  loading: true,
});


export const AuthContextProvider: FC<{children: ReactNode}> = ({children}) => {
  const [loading, setLoading] = useState(true);
  const [shouldLogin, setShouldLogin] = useState(false);
  const [accessToken, setAccessToken] = useState<string>();
  const [user, setUser] = useState<UserData>();

  const loginRedirect = useCallback(()=>{
    window.location.href = authorizationUrl;
  }, [])

  const logoutRedirect = useCallback(()=>{
    localStorage.removeItem('accessToken');
    const logoutUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURIComponent(window.location.origin)}&redirect_uri=${redirectUri}`
    window.location.href = logoutUrl;
  }, [])

  useEffect(() => {
    if(window.location.pathname === '/callback') {
      const urlParams = new URLSearchParams(window.location.search);
      const accessToken = urlParams.get('accessToken');
      if(accessToken && accessToken !== 'undefined'){ 
        setAccessToken(accessToken);
        localStorage.setItem('accessToken', accessToken);
        setShouldLogin(false);
        setLoading(false);
      }
    }
  }, [])

  const fetchUserData = async (accessTokenArg?: string) => {
    const userResponse =  await fetch(`${process.env.REACT_APP_AUTH_BASE}/me`, {
      headers: {
        Authorization: `Bearer ${accessTokenArg || accessToken}`
      }
    })
    if(userResponse.ok){
      const user =  await userResponse.json();
      localStorage.setItem('user', JSON.stringify(user));
      if(!user.email){
        throw new CustomError(ErrorCodes.USER_EMAIL_NOT_FOUND);
      }
      setUser(user);
      setLoading(false);
      setShouldLogin(false);
    }else{
      setUser(undefined);
      setLoading(false);
      setShouldLogin(true);
    }

  }

  const updateToken = async () => {
    try{
      const result = await fetch(`${process.env.REACT_APP_AUTH_BASE}/updateToken`, {
        credentials: 'include'
      })
      if(result.status === 401) {
        setShouldLogin(true);
        setLoading(false);
        localStorage.removeItem('accessToken');
        return;
      }else{
        const data = await result.json();
        if(data.accessToken && data.accessToken !== 'undefined'){
          setAccessToken(data.accessToken);
          localStorage.setItem('accessToken', data.accessToken)
          await fetchUserData(data.accessToken);
        }else{
          setShouldLogin(true);
          setLoading(false);
          return
        }
      }
    }catch(e){
      console.error(e);
      setShouldLogin(true);
      setLoading(false);
    }

  };

  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    const storedUser = localStorage.getItem('user');
    if(!navigator.onLine && storedToken && storedUser) {
      setAccessToken(storedToken);
      setUser(JSON.parse(storedUser));
      setLoading(false);
      setShouldLogin(false);
    }else if(localStorage.getItem('accessToken')) {
     updateToken();
    }else{
      setLoading(false);
      setShouldLogin(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const value = useMemo(() => ({ 
    accessToken, 
    shouldLogin, 
    loginRedirect,
    logoutRedirect,
    loading,
    user,
  }), [
    accessToken,
    shouldLogin, 
    loginRedirect, 
    logoutRedirect,
    loading,
    user
]);
  return <AuthContext.Provider value={value}>
    {children}
    </AuthContext.Provider>
};

export const useAuthContext = () => useContext(AuthContext);