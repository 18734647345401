import React, { FunctionComponent, useEffect, useState } from "react";
import { routes } from "src/App.routes";
import { Button } from "src/components/atoms/button/button";
import { Container } from "src/components/atoms/container/container";
import { Icon } from "src/components/atoms/icon/icon";
import { DetailsSkeleton } from "src/components/atoms/skeleton/detailsSkeleton";
import { Text } from "src/components/atoms/text/text";
import { Wysiwyg } from "src/components/atoms/wysiwyg/wysiwyg";
import { Description } from "src/components/molecules/description/description";
import { Hero } from "src/components/molecules/hero/hero";
import moment from "src/helpers/moment";
import { OfferDetail } from "src/types/ApiItems";
import { Page } from "../../../layouts/page";

type HrSingleJobProp = {
  offer: OfferDetail;
};

export const HrSingleJob: FunctionComponent<HrSingleJobProp> = ({ offer }) => {
  const [publishDate, setPublishDate] = useState<String>();
  useEffect(() => {
    setPublishDate(moment(offer?.publishDate).format("DD MMMM YYYY"));
    // eslint-disable-next-line
  }, [offer]);
  return (
    <Page pageClass="layout__detail singleJob">
      {offer ? (
        <>
          <Hero
            href={routes.job}
            title="Annonces RH"
            image={offer.imageUrl}
            badge={offer.isNew ? "new" : undefined}
          ></Hero>
          <Container>
            <Text type="subtitle">{offer.category}</Text>

            <Text type="h1">{offer.title}</Text>

            <div className="publish">
              <Icon name="time" />
              <Text type="p3" color="paleBrown">{`Publié le ${publishDate}`}</Text>
            </div>

            <div className="separator" />
            <Description
              title1="division"
              desc1={offer.division}
              title2="taux d'activité"
              desc2={offer.activityRate}
              title3="type de poste"
              desc3={offer.posteType}
              title4="lieu de travail"
              desc4={offer.location}
            />
            <div className="separator" />

            <Text type="h4">Mission</Text>
            <Wysiwyg content={offer.mission} />

            <Text type="h4">Responsabilités</Text>

            <Wysiwyg content={offer.formation} />

            <Text type="h4">Profil recherché</Text>
            <Wysiwyg content={offer.skills} />

            <div className="firstfield">
              <Text type="subtitle">Délai de postulation</Text>
              <Text type="h4">{moment(offer.delay).format("DD.MM.YY")}</Text>
            </div>

            <div className="field">
              <Text type="subtitle">Entrée en fonction</Text>
              {/* Si la date de début est dans moins de 10 jours, affiche "Dès que possible" sinon la date */}
              <Text type="h4">
                {offer.startDate ? moment(offer.startDate).format("DD.MM.YY") : "Dès que possible"}
              </Text>
            </div>

            <div className="field">
              <Text type="subtitle">Demande de renseignements</Text>
              <Text type="h4">{offer.informationRequest}</Text>
            </div>

            <div className="lastfield">
              <Text type="subtitle">Code de l'offre</Text>
              <Text type="h4">{offer.reference}</Text>
            </div>

            {/* Ecrit en dur */}
            <Wysiwyg content={offer.notice} />

            <Button large color="secondary" outline ahref={`mailto:${offer.contactHR}`}>
              Contacter les rh
            </Button>
          </Container>
        </>
      ) : (
        <DetailsSkeleton title="Annonces rh" withImage withDescription />
      )}
    </Page>
  );
};
