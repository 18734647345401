import React, { FunctionComponent } from "react";
import { Portal } from "react-portal";
import { bemModifier } from "src/helpers/bemModifier";

export type LoadingScreenProps = {
  backdrop?: boolean;
  message?: string;
  show?: boolean;
};
export const LoadingScreen: FunctionComponent<LoadingScreenProps> = ({
  message,
  show,
  children,
  ...modifiers
}) => {
  if (!show) return <></>;
  return (
    <Portal>
      <div className={bemModifier(modifiers, "loading-screen")}>
        <div className="loading-screen__content">
          <svg
            className="loading-screen__spinner"
            width="65px"
            height="65px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="loading-screen__path"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
          {(message || children) && (
            <div className="loading-screen__message">
              {message}
              {children}
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};
LoadingScreen.defaultProps = {
  show: false,
};
