import React, { FunctionComponent } from "react";
import { routes } from "src/App.routes";
import { Button } from "src/components/atoms/button/button";
import { LoadingScreen } from "../loadingScreen/loadingScreen";
export type OfflineLockProps = {};
export const OfflineLock: FunctionComponent<OfflineLockProps> = () => {
  return (
    <LoadingScreen
      message="Cette fonctionnalité n'est pas disponible en mode hors-ligne"
      show={true}
      backdrop
    >
      <div className="offline-lock">
        <Button
          link={routes.home}
          className="offline-lock__button"
          color="secondary"
          icon="arrow_left"
        >
          Retour à l'accueil
        </Button>
      </div>
    </LoadingScreen>
  );
};
