import moment from "moment";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { routes } from "src/App.routes";
import { Container } from "src/components/atoms/container/container";
import { HeaderDocument } from "src/components/organisms/headDocument/headerDocument";
import { ListDocument } from "src/components/organisms/lists/listDocument/listDocument";
import { byteToMb } from "src/components/organisms/lists/listDocumentItem/listDocumentItem";
import { AppContext } from "src/contexts/appContext";
import { isRoute } from "src/helpers/routeHelpers";
import { File } from "src/types/ApiItems";
import { DocumentBase } from "../documentBase/documentBase";

export const DocumentFolder = () => {
  const { id } = useParams();
  const { api } = React.useContext(AppContext);
  const [folder, setFolder] = React.useState<File | any>();
  const [files, setFiles] = React.useState<File | any>();
  const [breadcrumbItems, setBreadcrumbItems] = React.useState<File | any>();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true) 
  React.useEffect(() => {
    // Get folder and make tree root
    if (isRoute(routes.documentFolder, location.pathname)) {
      api.documentReperes.getFolderById(id).then((result) => {
        setFolder(result.folder);
        setFiles(result.folder.children);
        setBreadcrumbItems(result.breadcrumb);
        setLoading(false)
      });
    }
  }, [location]); // eslint-disable-line

  return (
    <DocumentBase loading={loading} back={routes.documentsImportant}>
      {folder && (
        <HeaderDocument
          title={folder.title}
          fileCount={folder.children.length}
          date={moment(folder.publishedOn).format("DD.MM.YY")}
          size={byteToMb(folder.size)}
          items={breadcrumbItems}
        />
      )}
      <Container yPadding={false}>
        <ListDocument loading={loading} files={files} />
      </Container>
    </DocumentBase>
  );
};
