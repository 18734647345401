import React, { FunctionComponent } from "react";
import { routes } from "src/App.routes";
import { Announce } from "src/types/ApiItems";
import { Button } from "../../../atoms/button/button";
import { Container } from "../../../atoms/container/container";
import { Icon } from "../../../atoms/icon/icon";
import { ListItemSkeleton } from "../../../atoms/skeleton/listItemSkeleton";
import { Text } from "../../../atoms/text/text";
import { AnnounceItem } from "../../announceItem/announceItem";
import { List } from "../list/list";

export type ListAnnounceProps = {
  announces?: Announce[];
  current?: any;
};

export const ListAnnounce: FunctionComponent<ListAnnounceProps> = ({ announces, current }) => {
  if (!announces)
    return (
      <Container small left>
        <ListItemSkeleton numberItem={3} />
      </Container>
    );
  if (announces.length === 0 && current === "my")
    return (
      <>
        <div className="announceEmpty">
          <Icon name="ping" />
          <Text type="h4" center>
            Section vide
          </Text>
          <Text type="p3" color="paleBrown" center>
            Vous n'avez publié aucune annonce pour le moment
          </Text>
          <Button icon="plus" link={routes.announcesCreate}>
            Publier une petite annonce
          </Button>
        </div>
      </>
    );
  if (announces.length === 0)
    return (
      <>
        <div className="announceEmpty">
          <Icon name="ping" />
          <Text type="h4" center>
            Section vide
          </Text>
          <Text type="p3" color="paleBrown" center>
            Aucune annonce n'a été publié pour le moment
          </Text>
        </div>
      </>
    );
  return (
    <Container small left>
      <List>
        {announces.map((announce, index) => (
          <AnnounceItem announce={announce} key={index} />
        ))}
      </List>
    </Container>
  );
};
